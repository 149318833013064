import React from 'react';
import Header from '../header/index';
import Footer from '../footer/index';
import './layout.scss';

export default ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
  </div>
);
