import React from 'react';
import { Link } from 'gatsby';
import footer from './footer.module.scss';

export default () => (
  <footer>
    <div className={footer.wrapper}>
      <div className={footer.list}>
        <h6>Locals路客</h6>
        <p>
          路客覆盖全球65+城市，15000+精品民宿，特色别墅、历史建筑、西关大屋、四合院、高档住宅…和懂生活的房东一起，体验当地最具特色的吃喝玩乐。
        </p>
      </div>
      <div className={footer.list}>
        <h6>微信公众号</h6>
        <img
          src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/asset/bottom_erweima.png"
          alt="微信公众号"
        />
      </div>
      <div className={footer.list}>
        <h6>微信小程序</h6>
        <img
          src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/asset/bottom_erweima2.png"
          alt="微信小程序"
        />
      </div>
      <div className={footer.list}>
        <h6>关于我们</h6>
        <div>
          {/* <Link to="/brand">品牌介绍</Link> */}
          <Link to="/media">媒体报道</Link>
          <Link to="/business">商务合作</Link>
          <Link to="/contact">联系我们</Link>
        </div>
      </div>
      <div className={footer.list}>
        <h6>客服热线</h6>
        <p>
          010-59897327
          <br />
          09:00-21:00
        </p>
        <div className={footer.friend_link}>
          <a
            href="https://www.toutiao.com/c/user/58701192385/#mid=1563982492281858"
            target="_blank"
          >
            <img
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/asset/toutiao.png"
              alt="toutiao"
            />
          </a>
          <a
            href="https://www.xiaohongshu.com/user/profile/5bad856bc5bf160001d00dff?xhsshare=CopyLink&appuid=5a6846fbdb2e6012374e45ef&apptime=1574903529"
            target="_blank"
          >
            <img
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/asset/xiaohongshu.png"
              alt="xiaohongshu"
            />
          </a>
          <a href="https://weibo.com/u/6073543841?is_hot=1" target="_blank">
            <img
              src="https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/asset/weibo.png"
              alt="weibo"
            />
          </a>
        </div>
      </div>
    </div>
    <div className={footer.copyright} onClick={() => window.open('https://beian.miit.gov.cn/')}>
      ©2020北京路客互联网科技有限公司｜京ICP备16004778号
    </div>
  </footer>
);
