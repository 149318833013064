/**
 * @author Sky Lin
 * @date 2019/12/16
 */
import axios from 'axios';

/**
 * 获取网站配置json
 * @returns {AxiosPromise}
 */
const loadConfig = () =>
  axios({
    type: 'get',
    url:
      'https://locals-house-prod.oss-cn-shenzhen.aliyuncs.com/localhomeqy/official-web/official-web.json',
  });

export { loadConfig };
